import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ShareButtons } from "../components/styled/ShareButtons";
import SEO from "../components/Seo";

const BlogPostContentfulTemplate = ({ data, pageContext, location }) => {
  const post = data.contentfulBlogPost;
  const image = getImage(post.image);
  const { previous, next } = pageContext;
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="heading4">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className="heading5">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className="heading6">{children}</h6>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img
          style={{ width: `100%` }}
          src={`https:${node.data.target.fields.file["en-US"].url}`}
          alt={node.data.target.fields.title["en-US"]}
        />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="copy">{children}</p>
      ),
      [BLOCKS.QUOTE]: (node, children) => <BlockQuote>{children}</BlockQuote>,
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <strong className="bold">{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
    },
  };
  return (
    <Layout location={location}>
      <SEO
        title={post.title}
        description={post.excerpt.excerpt}
        image={post.imageUrl.file.url}
      />
      <Article>
        <Title>{post.title}</Title>
        <Date>{`By ${post.author} // ${post.date}`}</Date>
        <GatsbyImage image={image} alt={post.title} />
        <section>{renderRichText(post.content, options)}</section>
        <hr />
        <ShareButtons url={`https://theopensession.org/blog/${post.slug}`} />
        <hr />
        <StyledNav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li style={{ maxWidth: "7rem" }}>
              {next && (
                <Link to={`/blog/${next.slug}`} rel="next">
                  ←{next.title}
                </Link>
              )}
            </li>
            <li style={{ maxWidth: "7rem" }}>
              {previous && (
                <Link to={`/blog/${previous.slug}`} rel="prev">
                  {previous.title}→
                </Link>
              )}
            </li>
          </ul>
        </StyledNav>
      </Article>
    </Layout>
  );
};

export default BlogPostContentfulTemplate;

export const pageQuery = graphql`
  query ContentfulBlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      author
      slug
      date: createdAt(formatString: "MMMM DD, YYYY")
      content {
        raw
      }
      excerpt {
        excerpt
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      imageUrl: image {
        file {
          url
        }
      }
    }
  }
`;
const BlockQuote = styled.blockquote`
  font-family: "Times New Roman", Times, serif;
  color: rgba(54, 54, 54, 60%);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 40px;
  padding-left: 15px;
  border-left: 4px solid var(--primary-color);
`;
const Article = styled.article`
  margin: 1rem;
  color: #363636;
  .bold {
    font-weight: 700;
  }
  .copy {
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-family: "Helvetica", sans-serif;
    margin: 0.5rem 0;
    color: #4b4949;
    line-height: 1.6;
  }
  @media screen and (min-width: 720px) {
    max-width: 45rem;
    margin: 0 auto;
  }
`;

const Title = styled.h1`
  font-size: calc(32px + (36 - 12) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  color: #525050;
  margin-top: 1rem;
`;

const Date = styled.p`
  font-size: calc(8px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  text-align: center;
  letter-spacing: 3px;
  color: #b4b2b2;
  margin-bottom: 1rem;
`;

const StyledNav = styled.nav`
  color: black;
  a:hover {
    color: #cca43b;
  }
  a {
    text-decoration: none;
  }
  @media screen and (min-width: 720px) {
    max-width: 45rem;
    margin: 1rem auto;
  }
`;
