import React from "react";
import styled from "styled-components";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  PocketShareButton,
  PocketIcon,
} from "react-share";

const StyledSection = styled.section`
  margin: 1rem 0;
  svg {
    margin-right: 1rem;
  }
  button:hover {
    transform: scale(1.05);
  }
`;

export const ShareButtons = ({ url }) => {
  return (
    <StyledSection>
      <p>Share</p>

      <FacebookShareButton url={url}>
        <FacebookIcon round size="25px" />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon round size="25px" />
      </TwitterShareButton>
      <EmailShareButton url={url}>
        <EmailIcon round size="25px" />
      </EmailShareButton>
      <PocketShareButton url={url}>
        <PocketIcon round size="25px" />
      </PocketShareButton>
    </StyledSection>
  );
};
